import React, {Component, useEffect, useState} from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import { graphql, Link } from "gatsby"
import get from "lodash/get"
import Img from "gatsby-image"
import ArticleContent from "../components/article/ArticleContent"
import styled from "styled-components"
import colors from "../styles/colors"
import fonts from "../styles/fonts"
import breakpoints from "../styles/breakpoints"
import { RichText, Date } from "prismic-reactjs"
import { Container, Row, Col } from "styled-bootstrap-grid"
import LinkedInIcon from "../resources/img/social/linkedin.svg"
import FacebookIcon from "../resources/img/social/facebook.svg"
import TwitterIcon from "../resources/img/social/twitter.svg"

const RowStyled = styled(Row)`
    margin: 0 33px 0 32px;
`

const IntroText = styled.div`
    font-size: 24px;
    color: ${colors.darkBlue};
    letter-spacing: -0.69px;
    line-height: 34px;
    ${fonts.jaggerRegular};
    margin-bottom: 60px;
    @media(min-width: ${breakpoints.lg}){
        margin-bottom: 70px;
        font-size: 28px;
        letter-spacing: -0.8px;
        line-height: 42px;
    }
`

const ArticleDate = styled.div`
    text-align: center;
    ${fonts.dmSansBold};
    font-size: 11px;
    color: ${colors.grey};
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin: 170px 33px 30px 33px;
    @media(min-width: ${breakpoints.md}){
        margin-top: 219px;
        margin-bottom: 21px;
    }
`

const ArticleTitle = styled.h1`
    text-align: center;
    ${fonts.jaggerRegular};
    font-size: 32px;
    color: ${colors.darkBlue};
    letter-spacing: -1.03px;
    line-height: 42px;
    max-width: 480px;
    margin-bottom: 60px;
    margin-left: 33px;
    margin-right: 33px;
    @media(min-width: ${breakpoints.md}){
        margin-left: auto;
        margin-right: auto;
    }
    @media(min-width: ${breakpoints.lg}){
        margin-bottom: 120px;
    }
`

const ArticleImg = styled(Img)`
    margin-left: 33px;
    margin-right: 33px;
    height: 232px;
    z-index: 1;
    
    img {
      object-fit: cover !important;
    }
    
    @media(min-width: ${breakpoints.md}){
        height: 400px;
    }
    @media(min-width: ${breakpoints.lg}){
        height: 624px;
    }
`

const SocialMediaButtons = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 35px;
  
  @media(min-width: ${breakpoints.lg}){
    bottom: 76px
  }
`

const SocialMediaButtonsBottom = styled.div`
  display: flex;
  align-items: center;
  margin-top: 66px;
`

const StyledButtons = styled.a`
  display: block;
  margin-left: 15px;
  border: none;
  background: transparent;
  cursor: pointer;
  
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
    -webkit-tap-highlight-color: transparent;
  }
`

const IconStyled = styled.img`
    height: 16px;
    width: 19px;
`

const ShareButton = styled.div`
    text-transform: uppercase;
    ${fonts.dmSansBold};
    font-size: 11px;
    color: ${colors.darkBlue};
    letter-spacing: 1.5px;
    border: none;
    background: transparent;
  
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        -webkit-tap-highlight-color: transparent;
    }
`
const ReadNext = styled.div`
    ${fonts.dmSansBold};
    color: ${colors.grey};
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    padding-bottom: 35px;
    padding-top: 90px;
    @media(min-width: ${breakpoints.md}){
        padding-top: 0;
    }
    @media(min-width: ${breakpoints.lg}){
        padding-bottom: 38px;
    }
`

const StyledLink = styled(Link)`
    ${fonts.jaggerRegular};
    font-size: 15px;
    color: ${colors.darkBlue};
    letter-spacing: -0.42px;
    line-height: 21px;
    text-decoration: none;
    display: flex;
    align-items: center;
    &:hover,
    &:active,
    &:focus {
        text-decoration: underline;
        outline: none;
        box-shadow: none;
        transition: .5s;
    }
`

const StyledImg = styled(Img)`
    width: 136px;
    height: 91px;
    min-width: 136px;
    display: inline-block;
    margin-right: 25px;
    @media(min-width: ${breakpoints.md}){
        min-width: 115px;
        width: 115px;
        height: 77px;
    }
`

const SidebarArticle = styled.div`
    margin-bottom: 21px;
`

const Title = styled.div`
    display: inline-block;
    @media(min-width: ${breakpoints.lg}){
    max-width: 160px;
    }
`

const ArticleContainer = styled(Container)`
  position: relative;
`;

const ArticleContainerBackground = styled.div`
    position: absolute;
    width: 30%;
    height: 104%;
    background: ${colors.lightBlue};
    display: none;
    bottom: 0;
    @media(min-width: ${breakpoints.lg}){
      display: block;
    }
`;

const ColStyled = styled(Col)`
  padding-top: 79px;
  padding-bottom: 90px;
  background: ${props => props.blue ? "transparent" : colors.white};
  box-sizing: border-box;

  @media(min-width: ${breakpoints.lg}){
      padding-top: 130px;
      padding-bottom: 150px;
      padding-left: 0;
      background: ${props => props.blue ? colors.lightBlue : colors.white};
  }
`

const ArticleContainerBackgroundMobile = styled.div`
    position: absolute;
    width: 100%;
    height: 765px;
    background: ${colors.lightBlue};
    display: block;
    bottom: 0;
    z-index: -1;
    @media(min-width: ${breakpoints.sm}){
        height: 765px;
    }
    @media(min-width: ${breakpoints.md}){
        height: 619px;
    }
    @media(min-width: ${breakpoints.lg}){
      display: none;
    }
`

function ArticleTemplate({ data, location }) {
  const path = location.pathname
  let article = get(data, "prismic.article.edges").slice(0, 1).pop()
  const articles = get(data, "prismic.articles.edges")
  const [url, setUrl] = useState('')

  useEffect(() => {
    if (typeof window === 'object') {
      setUrl(encodeURIComponent(window.location.href))
    }
  }, [])

  if (!article) return null

  article = article.node

  const date = Date(article.published_at)
  const publishedAt = Intl.DateTimeFormat(article._meta.lang, {
    year: "numeric",
    month: "short",
    day: "2-digit",
  }).format(date)

  const description = (article.intro && article.intro.length > 0) ? article.intro[0].text : null

  let articlesData = []
  const currentArticleId = article._meta.uid

  articles.map((articleData) => {
    const node = articleData.node

    if (articlesData.length >= 4 || node._meta.uid === currentArticleId) {
      return
    }

    if (!node.thumbnail_imageSharp) {
      return
    }

    articlesData.push(node)
  })

  let links = [
    {
      icon: LinkedInIcon,
      href: `https://www.linkedin.com/sharing/share-offsite/?url=${url}`
    },
    {
      icon: FacebookIcon,
      href: `https://www.facebook.com/sharer/sharer.php?u=${url}`
    },
    {
      icon: TwitterIcon,
      href: `https://twitter.com/share?text=${article.title}%20&url=${url}&hashtags=&ref_src=twsrc%5Etfw`
    },
  ]

  return (
    <Layout meta={article._meta}>
      <SEO title={article.title}
           description={description}
           path={path}/>
      <Container>
        <ArticleDate>{publishedAt}</ArticleDate>
        <ArticleTitle>{article.title}</ArticleTitle>
        <ArticleImg fluid={article.hero_imageSharp.childImageSharp.fluid} alt={article.title}/>
      </Container>
      <ArticleContainer fluid>
        <ArticleContainerBackground/>
        <Container>
          <RowStyled>
            <ColStyled lg={9} lgOrder={2}>
              <Row>
                <Col lg={12}>
                  <SocialMediaButtons>
                    <ShareButton>
                      Share
                    </ShareButton>
                    {
                      links.map((item, index) => {
                        return (
                          <StyledButtons key={index} key={index} href={item.href} data-show-count="false" target="_blank" rel="noreferrer">
                            <IconStyled src={item.icon}/>
                          </StyledButtons>
                        )
                      })
                    }
                  </SocialMediaButtons>
                </Col>
                <Col lg={8} lgOffset={2}>
                  <IntroText>{RichText.render(article.intro)}</IntroText>
                  <ArticleContent article={article}/>
                  <SocialMediaButtonsBottom>
                    <ShareButton>Share</ShareButton>
                    {
                      links.map((item, index) => {
                        return (
                          <StyledButtons key={index} href={item.href} data-show-count="false" target="_blank" rel="noreferrer">
                            <IconStyled src={item.icon}/>
                          </StyledButtons>
                        )
                      })
                    }
                  </SocialMediaButtonsBottom>
                </Col>
              </Row>
            </ColStyled>
            <ColStyled lg={3} lgOrder={1} blue side>
              <ReadNext>Read Next</ReadNext>
              {articlesData.map((article) => {
                return (
                  <SidebarArticle>
                    <StyledLink to={`/article/${article._meta.uid}`}>
                      <StyledImg fluid={article.thumbnail_imageSharp.childImageSharp.fluid} alt={article.title}/>
                      <Title>{article.title}</Title>
                    </StyledLink>
                  </SidebarArticle>
                )
              })}
            </ColStyled>
          </RowStyled>
        </Container>
        <ArticleContainerBackgroundMobile/>
      </ArticleContainer>
    </Layout>
  )
}

export default ArticleTemplate

export const query = graphql`
    query articleQuery($lang: String, $uid: String) {
        prismic {
            articles: allArticles(lang: $lang, sortBy: published_at_DESC, first: 5) {
                edges {
                    node {
                        _meta {
                          uid
                          lang
                          alternateLanguages {
                            lang
                            type
                            uid
                          }
                        }
                        title
                        thumbnail_image
                        thumbnail_imageSharp {
                            childImageSharp {
                                fluid(quality: 100, maxWidth: 230, maxHeight: 144) {
                                    ...GatsbyImageSharpFluid_withWebp_noBase64
                                }
                            }
                        }
                    }
                }
            }
            article: allArticles(lang: $lang, uid: $uid) {
                edges {
                    node {
                        _meta {
                          uid
                          lang
                          alternateLanguages {
                            lang
                            type
                            uid
                          }
                        }
                        published_at
                        title
                        hero_image
                        hero_imageSharp {
                            childImageSharp {
                                fluid(quality: 100, maxWidth: 1920) {
                                    ...GatsbyImageSharpFluid_withWebp_noBase64
                                }
                            }
                        }
                        body {
                            __typename
                            ... on PRISMIC_ArticleBodyContent {
                                type
                                primary {
                                    content
                                }
                            }
                            ... on PRISMIC_ArticleBodyBlock_carousel {
                                type
                                fields {
                                    image
                                    imageSharp {
                                        childImageSharp {
                                            fluid(quality: 100, maxWidth: 1920, maxHeight: 930) {
                                                ...GatsbyImageSharpFluid_withWebp_noBase64
                                            }
                                        }
                                    }
                                }
                            }
                            ... on PRISMIC_ArticleBodyBlock_text_image {
                                type
                                primary {
                                    content
                                    image
                                    imageSharp {
                                        childImageSharp {
                                            fluid(quality: 100, maxWidth: 720, maxHeight: 720) {
                                                ...GatsbyImageSharpFluid_withWebp_noBase64
                                            }
                                        }
                                    }
                                    image_position
                                }
                            }
                            ... on PRISMIC_ArticleBodyBlock_video {
                              type
                              primary {
                                video
                              }
                            }
                        }
                    }
                }
            }
        }
    }
`

